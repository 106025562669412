<template>
  <div
    id="SearchOverlayMobile"
    class="pure-form"
  >
    <SearchInputField
      :focus="focus"
      @search-blur="$emit('search-blur')"
      @search-triggered="runSearch"
      @searchTerm="getOverlay"
      :is-loading="isLoading"
      :is-inch-search="isInchSearch"
      @changeUnit="handleChange"
    />
    <div
      v-if="searchTermResult?.customerSuggestions?.length > 0"
      class="overlay__search-suggestions"
    >
      <div class="overlay__search-suggestions__headline">
        {{ $tv('suggestions', 'searchResults') }}
      </div>
      <div class="overlay__search-suggestions__results">
        <div
          v-for="(suggestion, index) in searchTermResult?.customerSuggestions.slice(0, 4)"
          :key="index"
        >
          <div
            class="suggestion-result"
            @click="openBuyBoxSku(suggestion.Sku, index)"
          >
            <CustomerItemNumberIcon /> <span>{{ suggestion.CustomerSku }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length"
      class="search-overlay__content"
    >
      <div class="h5">
        <span v-tv:searchResults="'AmountOfProducts'" />: <span
          v-text="searchTermResult.count"
        />
      </div>
      <NewSearchOverlayResults
        :products="mappedProducts"
        :l2categories="l2categories"
        :categories="categories"
        :search-term="searchTerm"
        :is-any-search="isAnySearch"
        @close-overlay="$emit('close-overlay')"
        @openBuyBox="openBuyBox"
        @clickItem="clickItem"
      />
    </div>
    <div
      v-else-if="searchTermResult && searchTermResult.searchResults && searchTermResult.searchResults.length === 0 && !searchTermResult?.customerSuggestions?.length > 0"
      class="search-overlay__content"
    >
      <div
        class="h5"
        v-tv:searchResults="'NoProducts'"
      />
    </div>
    <div
      v-else-if="searchTermResult && !searchTermResult?.customerSuggestions?.length > 0"
      class="overlay__skeleton"
    >
      <SearchSkeleton
        @close="closeOverlay()"
        :mobile="true"
      />
    </div>
  </div>
</template>

<script>
import NewSearchOverlayResults from '~/components/search/NewSearchOverlayResults.vue'
import SearchInputField from '~/components/search/SearchInputField.vue'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'
import SearchSkeleton from '~/components/skeletons/SearchSkeleton.vue'
import CustomerItemNumberIcon from 'assets/icons/customer_item_number.svg'

export default {
  components: {
    CustomerItemNumberIcon,
    NewSearchOverlayResults, SearchInputField, SearchSkeleton
  },
  props: {
    focus: {
      type: Boolean
    },
    searchTerm: {
      type: String,
      default() {
        return ''
      }
    },
    l2categories: {
      type: Array,
      default () {
        return []
      }
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    searchTermResult: {
      type: Object,
      default () {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    isInchSearch: {
      type: Boolean,
      default () {
        return false
      }
    },
    isAnySearch: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    runSearch() {
      this.$emit('close-overlay')
    },
    getOverlay(value) {
      this.$emit('search-term', value)
    },
    openBuyBox(product) {
      this.$emit('openBuyBox', product)
    },
    openBuyBoxSku(sku, index) {
      this.$searchInsights?.trackEvent({
        name: 'ClickSuggest', properties: {
          searchServiceName: this.searchTermResult?.azureSearchTelemetry?.searchServiceName,
          searchId: this.searchTermResult?.azureSearchTelemetry?.searchId,
          clickedDocId: sku,
          sku: sku,
          rank: index
        }
      })
      this.$emit('openBuyBoxSku', sku)
    },
    handleChange() {
      this.$emit('changeUnit')
    },
    clickItem(sku, rank) {
      this.$emit('clickItem', sku, rank)
    }
  },
  computed: {
    mappedProducts() {
      return getMappedProducts(this.searchTermResult)
    }
  }
}
</script>

<style lang="scss">
[id='SearchOverlayMobile'] {
  margin-top: -.9rem;
  position: relative;
  justify-content: center;
  align-items: center;

  .overlay__search-suggestions {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &__headline {
      font-size: 1rem;
      font-weight: $base-text-font-weight-bold;
      color: $color__fst__primary;
      margin-bottom: .5rem;
    }

    &__results {
      display: flex;
      gap: 0.75rem;

      .suggestion-result {
        position: relative;
        border-radius: 0.2rem;
        border: 0.063rem solid $color__fst__primary;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
        color: $color__fst__primary;

        span {
          margin-left: 1.25rem;
        }

        .icon--svg {
          position: absolute;
          top: .125rem;
          left: .375rem;
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }
  }

  .search-overlay__content {
    margin-top: 1rem;

    .h5 {
      margin-bottom: .5rem;
      font-size: 1rem;
    }
  }
}

.popup__wrapper:has(#SearchOverlayMobile) {
  width: 100%;
}
</style>
