<template>
  <div
    class="pageNavigation__breadcrumb__link"
  >
    <nuxt-link :to="localePath(link.path)">
      <span v-text="link.label" />
    </nuxt-link>
    <span
      class="pageNavigation__breadcrumb__link__separator"
      v-if="breadcrumb.length !== index+1"
      v-text="' > '"
    />
  </div>
</template>

<script>

export default defineComponent({
  props: {
    link: {
      type: Object,
      default() {
        return {}
      }
    },
    breadcrumb: {
      type: Array,
      default() {
        return []
      }
    },
    index: {
      type: Number,
      default() {
        return 0
      }
    }
  }
})
</script>
<style lang="scss">
.pageNavigation__breadcrumb__link,
.pageNavigation__breadcrumb__link__separator {
  display: inline-flex;
  align-items: center;
}

.pageNavigation__breadcrumb__link__separator {
  margin: 0 .4rem;
  color: $color__fst_gray__100;
  font-size: 1rem;
  font-weight: $base-text-font-weight-semi-bold;
}
</style>
